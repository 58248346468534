  .App {
    text-align: center;
  }

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }

  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .App-link {
    color: #61dafb;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .No-Break-Line {
    white-space: nowrap;
    display: inline;
  }

  .MobileStyle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ZhuanP {
    /* margin: 0 auto; */
    /* width: 40rem; */
  }

  .ZhuanLocationInput {
    width: 6rem;
    height: 2rem;
    font-size: 15px;
  }

  .CuzH2 {
    margin-bottom: 10px;
  }

  .ZhuanFuncButton {
    width: 10rem;
    height: 4rem;
    font-size: 15px;
  }

  .suggestions {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
  }
  
  .suggestions li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .suggestions li:hover {
    background-color: #f0f0f0;
  }
  
  .selectedItemBox {
    margin-bottom: 0.8rem;
    font-size: 18px;
  }

  .selectedItem {
    margin-right: 0.3rem;
    border: 5 solid red;
    position: relative;
    margin-bottom: 23px;
  }
  
  .deleteIcon {
    /* position: relative; */
    /* top: -6px; */
    /* right: 4px; */
    cursor: pointer;
    color: red;
    /* background-color: red; */
    /* border-radius: 50%; */
    /* color: red; Customize the color of the delete icon */
  }
  
  .circle {
    /* width: 10px;
    height: 10px; */
    /* background-color: red;
    border-radius: 50%; */
  }
  